import importMap from "~/../__generated__/import-map.json";

const ImportMap = () => {
  const port = import.meta.env.PORT ?? 3001;
  const hostname = import.meta.env.VERCEL_URL ?? import.meta.env.NODE_ENV === "production"
    ? import.meta.env.VERCEL_URL
    : `localhost:${port}`;
  const bundleUrl = `${hostname}/assets/bundle/widgets.js`
  const imports = { ...importMap.imports, "widgets": bundleUrl }
  const output = { ...importMap, imports }

  return (
    <>
      <textarea
        style={{
          width: "100%",
          height: "20rem",
          fontFamily: "monospace",
          fontSize: "calc(1rem / 16 * 14)",
          border: "1px solid #ccc",
          borderRadius: "0.5rem",
          padding: "1rem",
        }}
        value={JSON.stringify(output, null, 2)}
        readOnly
      />
    </>
  );
};

export default ImportMap;